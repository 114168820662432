import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { EMPTY, Observable, throwError, of } from "rxjs";
import { catchError, tap, finalize } from "rxjs/operators";
import { Auth } from "aws-amplify";
import { AuthStateService } from "../../shared/_service/auth-state.service";
// import { ToasterService } from "astrakode-bc-library";
import { AuditService, AuditStatus, AuditType } from "../../shared/_service/audit.service";
import {en, enUS, es, it,  zh, zhCN, itIT} from "../../../assets/i18n";
import { MatDialog } from "@angular/material/dialog";
import { PremiumDialogComponent } from "astrakode-bc-composer";
import { ToasterService } from "astrakode-bc-library";

// This is used to logout the user, when the server responds with an unathorized status code.
// Especially when the session token expires.
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthStateService,
    private injector: Injector,
    // private toasterService: ToasterService,
    private auditService: AuditService,
    private dialog: MatDialog,
  ) { }

  // Intercepter intercepts the responses, and then process based on the recieved status code
  errors = {
    en,
    es,
    zh,
    it,
    itIT,
    zhCN,
    enUS
  };

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let errCode = "";
    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.infos && evt.body.infos.length > 0) {
            const error = JSON.parse(localStorage.getItem('errorTranslations'));
            const lang = localStorage.getItem("lang") || "enUS";
            errCode = evt.body.infos[0].code;

            if (errCode == "STRIPE_NO_SEATS_LEFT_ERR") {
              let dialogRef = this.dialog.open(PremiumDialogComponent, {
                data: {
                    constraintsErrorsList: [{
                      label: "STRIPE_NO_SEATS_LEFT_ERR",
                      description: error[lang][errCode],
                      type: 1,
                      diagramElementId: 0,
                      attributeId: 0
                    }]
                },
              });
              // throw new TypeError(errCode);
              //using throwError the flow does not go inside the following catchError, but it will continue with the call flow
              return throwError({
                "message": evt.body.infos[0].message,
                "errCode": errCode
              });
            } else {
              throw new TypeError(error[lang][errCode]);
            }
          }
        }
      }),
      catchError((err) => {
        console.error(err);
        let errorToThrow: any = "";
        let errMsgToShow: string = "Error";

        if (err.error.errorCode == "NO_SEATS_LEFT_ERR") {
          const error = JSON.parse(localStorage.getItem('errorTranslations'));
          const lang = localStorage.getItem("lang") || "enUS";
          let dialogRef = this.dialog.open(PremiumDialogComponent, {
            data: {
                constraintsErrorsList: [{
                  label: "STRIPE_NO_SEATS_LEFT_ERR",
                  description: error[lang][errCode],
                  type: 1,
                  diagramElementId: 0,
                  attributeId: 0
                }]
            },
          });

          errorToThrow = err;
        } else if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          Auth.signOut()
            .then((data) => {
              this.authService.logout();
              // this._router.navigate(["/login"], { replaceUrl: true });
            })
            .catch((err) => console.log(err));
          
            errorToThrow = err;
        } 

        // err.error is not null, if the ResponsenEntity contains an Exception
        // err.error.message will give the custom message send from the server
        if (err.error?.type === "NoPair") {
          errorToThrow = err.error;
          if (err.error && err.error.errorMessage) {
            errMsgToShow = err.error.errorMessage;
          }
        } else {
          errorToThrow = 'Error';
          const error = typeof err.error === 'string' ? err.error : (err.message || err?.error?.message || err?.statusText || err?.stack || errorToThrow);                    
          let errMsg = error;
          const errResp = err.error;
          if (typeof errResp != 'string' && errResp) {
            if (errResp.errorMessage) {
              errMsg = errResp.errorMessage;
            }
            if (errResp.auditCode) {
              this.auditService.generateAudit(
                AuditStatus.Error,
                errResp.auditCode,
                errResp.auditParams
              );
            }
          }          
          if (err.status === 500 || err.status === 504 || err.status === 503 || errCode == "DAO_003" || errCode == "RE_000"){
            // console.log(errMsg);
            const lang = localStorage.getItem('lang') || 'enUS';
            const message = this.errors[lang]?.ErrorMessages.GatewayTimeout;
            errMsgToShow = message;
          } else {
            if (err.statusText === "Payment Required") {
              errMsgToShow = err.error;
            }else {
              errMsgToShow = errMsg;
            }
          }          
        }
        
        return throwError(errorToThrow);
      })
    );
  }

}
