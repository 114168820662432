import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { clearState } from './reducers/clearState.metareducer';
import { EffectsModule } from '@ngrx/effects';
import { reducer, effects } from "./indexs";


@NgModule({
  declarations: [],
  imports: [
    // shared store
    StoreModule.forFeature("servicesStore", reducer, {
      metaReducers: [clearState],
    }),
    // effects
    EffectsModule.forFeature(effects),
  ],
})
export class ServiceStoreModule { }
