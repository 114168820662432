import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'projects/AstrakodeBCPortal/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private apiUrl = 'https://api.openai.com/v1/completions'; // Replace with your endpoint
  private apiKey = 'YOUR_API_KEY_HERE';

  constructor(private http: HttpClient) {}

  generateCode(contractDescription: string, wsConnectionId: string, token: string): Observable<string> {
    const payload = {
      "projectName": "Test",
      "contractDescription": contractDescription,
      "wsConnectionId": wsConnectionId
    };
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post(
      `${environment.API_URL}aiSherpaGenerateSolidity`, 
      payload,
      { headers }
    ).pipe(
      map((response: any) => {
        return response.returnedObject.solidityCode;
      })
    );

    // return of("pragma solidity >=0.4.25 <0.9.0; contract Contract {uint256 public GLOBALdataEnt;event Event(uint256 dataEnt);constructor() {}}");
  }

  // callGptMemory(payload: {"messagesMemory": [], "message": string}): Observable<string> {
  //   const headers = new HttpHeaders({
  //     "Access-Control-Allow-Origin": "true",
  //   });
  //   return this.http.post(
  //     `http://localhost:4060/api/callGptMemory`, 
  //     payload,
  //     { headers }
  //   ).pipe(
  //     map((response: any) => {
  //       console.log(">>>>>>>>>>> callGptMemory response: ", response);
  //       return response;
  //     })
  //   );

  //   // return of("pragma solidity >=0.4.25 <0.9.0; contract Contract {uint256 public GLOBALdataEnt;event Event(uint256 dataEnt);constructor() {}}");
  // }

  // callGptMemory(payload: {"messagesMemory": [], "message": string}, token: string): Observable<string> {
  callGptMemory(payload: {"messagesMemory": [], "message": string, userToken: string}): Observable<string> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${payload.userToken}`,
    });
    return this.http.post(
      `${environment.API_URL}aiKodeSherpaChat`, 
      payload,
      { headers }
    ).pipe(
      map((response: any) => {
        return response?.returnedObject;
      })
    );

    // return of("pragma solidity >=0.4.25 <0.9.0; contract Contract {uint256 public GLOBALdataEnt;event Event(uint256 dataEnt);constructor() {}}");
  }

  audit(zipFile: Blob, wsConnectionId: string, userToken: string): Observable<any> {
    const formData = new FormData();
    formData.append("file", zipFile, 'genContract.zip');

    const headers = new HttpHeaders({
      Authorization: `${userToken}`,
    });
    return this.http.post(
      `${environment.API_URL}auditor_audit/${wsConnectionId}`, 
      formData,
      { headers }
    );
  }
}
