import { Injectable } from "@angular/core";
import { CALL_AI_KODE_SHERPA_CHAT, CallAIKodeSherpaChatError, CallAIKodeSherpaChatSuccess } from "../indexs";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, switchMap } from "rxjs/operators";
import { ChatService } from '../../pages/ai-service/chat-bot/chat.service';
import { of } from "rxjs";

@Injectable()
export class ServicesEffects {
    constructor(
        private actions$: Actions,
        private chatService: ChatService,
    ) {}

    callAIKodeSherpaChat$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(CALL_AI_KODE_SHERPA_CHAT),
        switchMap((action: {payload: any}) => {
            return this.chatService.callGptMemory(action.payload).pipe(
            switchMap((result: any) => ([new CallAIKodeSherpaChatSuccess(result)])),
            catchError((e) => of(new CallAIKodeSherpaChatError()))
            );
        })
        );
    });

}

export const effects = [ServicesEffects];