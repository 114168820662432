import { ActionReducerMap } from "@ngrx/store";
import * as servicesAction from "../actions/services.action";
import { cloneDeep } from "lodash";

export interface ServicesState { 
    userWebSocketConnectionId: string;
    gptResponse: string;
    chatBotMsgs: [];
    reqJson: string;
    smartcontractCode: string;
    monacoLoadingMsg: string;
    finishAuditReportFromAi: string
}

export const initialState: ServicesState = {
    userWebSocketConnectionId: "",
    gptResponse: "",
    chatBotMsgs: [],
    reqJson: "",
    smartcontractCode: "",
    monacoLoadingMsg: "",
    finishAuditReportFromAi: ""
}

export function reducer(
  state = initialState,
  action: servicesAction.ServicesActionsList
): ServicesState {
  switch (action.type) {
    case servicesAction.UPDATE_USER_WEBSOCKET_CONNECTION_ID: {
        return {
          ...state,
          userWebSocketConnectionId: action.payload
        };
    }
    case servicesAction.CALL_AI_KODE_SHERPA_CHAT_SUCCESS: {
        return {
          ...state,
          gptResponse: action.payload.gptResponse,
          chatBotMsgs: action.payload.messagesMemory.filter(message => message.role != "system"),
          reqJson: action.payload.reqJson
        };
    }
    case servicesAction.UPDATE_SMARTCONTRACT_CODE: {
        return {
          ...state,
          smartcontractCode: action.payload
        };
    }
    case servicesAction.UPDATE_MONACO_LOADING_MSG: {
        return {
          ...state,
          monacoLoadingMsg: action.payload
        };
    }
    case servicesAction.UPDATE_FINISHAUDITREPORT_FROM_AI: {
        let new_Date: Date = new Date();
        let result: string = new_Date.toLocaleString();
        return {
          ...state,
          finishAuditReportFromAi: result
        };
    }
  }
  return state;
}

// export const reducers: ActionReducerMap<UserPlanState> = {
//   productSubscription: productSubscriptionReducer,
// };
