import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ServicesState } from "../indexs";

export const getServicesState = createFeatureSelector<ServicesState>("servicesStore");

export const getChatBotMsgs = createSelector(
    getServicesState,
    (state: ServicesState) => state.chatBotMsgs
);

export const getReqJson = createSelector(
    getServicesState,
    (state: ServicesState) => state?.reqJson
);

export const getChatDetails = createSelector(
    getServicesState,
    getChatBotMsgs,
    getReqJson,
    (state: ServicesState, chatBotMsgs, reqJson) => { return {"chatBotMsgs": chatBotMsgs, "reqJson": reqJson}; }
);

export const getUserWebSocketConnectionId = createSelector(
    getServicesState,
    (state: ServicesState) => state?.userWebSocketConnectionId
);

export const getSmartcontractCode = createSelector(
    getServicesState,
    (state: ServicesState) => state?.smartcontractCode
);

export const getMonacoLoadingMsg = createSelector(
    getServicesState,
    (state: ServicesState) => state?.monacoLoadingMsg
);

export const getFinishAuditReportFromAi = createSelector(
    getServicesState,
    (state: ServicesState) => state?.finishAuditReportFromAi
);
