import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Auth } from "aws-amplify";
import { Store } from "@ngrx/store";
import { EntityCacheDispatcher} from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {sharedStore} from "astrakode-bc-library";
import { SharedStoreModule } from "../../shared-store/shared-store.module";

@Injectable({
  providedIn: "root",
})
export class AuthStateService {
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  public isLogOutSubject = new BehaviorSubject<boolean>(false);
  public isLogOutAppSubject = new BehaviorSubject<boolean>(false);
  
  constructor(
    private http: HttpClient,
    private entityCacheDispatcher: EntityCacheDispatcher,
    private store: Store<SharedStoreModule>,
    private _router: Router
  ) {

    if (localStorage.getItem("remember") === "false") {
      Auth.configure({ storage: sessionStorage });
      Auth.currentSession().then((data) => {
        if (data) {
          return;
        }
        this.logout();
      });
    }
  }

  /**
   * Delete user data from the localStorage
   *
   * @memberof AuthStateService
   */
  logout(): void {
    Auth.signOut().then(() => {
      localStorage.removeItem("Auth");
      this.isLoginSubject.next(false);
      // this.entityCacheDispatcher.clearCollections(["Project"]);
      // this.entityCacheDispatcher.clearCollections(["User"]);
      this.entityCacheDispatcher.clearCollections(null);
      this.store.dispatch(new sharedStore.Logout());
      this._router.navigate(["/login"], { replaceUrl: true });
    });
  }

  /**
   * Updates is login subject with true value
   *
   * @memberof AuthStateService
   */
  login(): void {
    this.store.dispatch(new sharedStore.UpdateIsLogging(true));
    this.isLoginSubject.next(true);
  }

  /**
   * Returns the login subject as a Observable
   *
   * @return {*}  {Observable<boolean>}
   * @memberof AuthStateService
   */
  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  /**
   * Returns the login subject
   *
   * @return {*}  {BehaviorSubject<boolean>}
   * @memberof AuthStateService
   */
  // isLoggedInSubject(): BehaviorSubject<boolean> {
  //   return this.isLoginSubject;
  // }

  /**
   * Checks if there is user info in the local storage
   *
   * @private
   * @return {*}  {boolean}
   * @memberof AuthStateService
   */
  hasToken(): boolean {
    return !!localStorage.getItem("Auth");
  }

}
