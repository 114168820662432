import { fromPairs } from "lodash";
import { Action } from '@ngrx/store';

export const UPDATE_STRIPE_CUSTOMER_ERROR = "[AK_StripeUpdateCustomer API] Update Stripe Customer ERROR";

export const UPDATE_STRIPE_CUSTOMER_INVOICES_ERROR = "[AK_StripeCustomerInvoices API] Update Stripe Customer Invoices ERROR";
export const UPDATE_STRIPE_CUSTOMER_INVOICES_SUCCESS = "[AK_StripeCustomerInvoices API] Update Stripe Customer Invoices SUCCESS";
export const GET_STRIPE_CUSTOMER_INVOICES_BILLINGPAGE = "[Billing Page] Calling AK_StripeCustomerInvoices";

export const UPDATE_RECURRING_INTERVAL = "[Plans Page] Update recurringInterval";
export const UPDATE_PLAN_TYPE = "[Plans Page] Update planType";

export const UPDATE_STRIPE_CUSTOMER_TAXID_ERROR = "[AK_StripeGetCustomerTaxId API] Update Stripe CustomerTaxId ERROR";
export const UPDATE_STRIPE_CUSTOMER_TAXID_SUCCESS = "[AK_StripeGetCustomerTaxId API] Update Stripe CustomerTaxId SUCCESS";
export const GET_STRIPE_CUSTOMER_TAXID_ON_APP_LOAD = "[On App Load] Calling AK_StripeGetCustomerTaxId";

export const UPDATE_STRIPE_TAXID_TYPES_ERROR = "[AK_StripeGetSupportedTaxIdTypes API] Update Stripe SupportedTaxIdTypes ERROR";
export const UPDATE_STRIPE_TAXID_TYPES_SUCCESS = "[AK_StripeGetSupportedTaxIdTypes API] Update Stripe SupportedTaxIdTypes SUCCESS";
export const GET_STRIPE_TAXID_TYPES_ON_APP_LOAD = "[On App Load] Calling AK_StripeGetSupportedTaxIdTypes";

export const CREATE_FREE_PLAN_SUBSCRIPTION_PLANS_PAGE = "[From Plans page] Creating a Free Plan";
export const CREATE_FREE_PLAN_SUBSCRIPTION_ON_APP_LOAD = "[On App Load] Creating a Free Plan";

export const UPDATE_SUBSCRIPTION_ERROR_STRIPE_SERVICE = "[AK_StripeUpdateSubscription API - Stripe updateSubscription service] Calling Stripe UpdateSubscription ERROR";
export const UPDATE_SUBSCRIPTION_SUCCESS_PLAN_PAGE = "[AK_StripeUpdateSubscription API - From Plan Page after payment] Calling Stripe UpdateSubscription SUCCESS";

export const DELETE_SUBSCRIPTION_ERROR = "[AK_StripeDeleteSubscription API] Calling AK_StripeDeleteSubscription ERROR";
export const DELETE_SUBSCRIPTION_SUCCESS = "[AK_StripeDeleteSubscription API] Calling AK_StripeDeleteSubscription SUCCESS";
export const DELETE_SUBSCRIPTION_FROM_BILLING_PAGE = "[Billing Page] Calling AK_StripeDeleteSubscription";
export const DELETE_SUBSCRIPTION_FROM_PLANS_PAGE = "[Plans Page] Calling AK_StripeDeleteSubscription";

export const DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL_ERROR = "[Enabling Trial AK_StripeDeleteSubscription API] Calling AK_StripeDeleteSubscription ERROR";
export const DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL_SUCCESS = "[Enabling Trial AK_StripeDeleteSubscription API] Calling AK_StripeDeleteSubscription SUCCESS";
export const DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL = "[Enabling Trial Effect] Calling AK_StripeDeleteSubscription";

export const CREATE_SUBSCRIPTION_ERROR = "[AK_StripeCreateSubscription API] Calling Stripe CreateSubscription ERROR";
export const CREATE_SUBSCRIPTION_SUCCESS = "[AK_StripeCreateSubscription API] Calling Stripe CreateSubscription SUCCESS";
export const CREATE_SUBSCRIPTION_FROM_EFFECT = "[createFreePlanSubscription Effect] Calling AK_StripeCreateSubscription";
export const CREATE_SUBSCRIPTION_FROM_PLANS_PAGE = "[Plans Page] Calling AK_StripeCreateSubscription";
export const CREATE_SUBSCRIPTION_ENABLING_TRIAL_FROM_PLANS_PAGE = "[Enable Trial from Plans Page] Calling AK_StripeCreateSubscription";

export const UPDATE_STRIPE_PRICESLIST_ERROR = "[AK_StripeGetPricesList API] Calling Stripe PricesList ERROR";
export const UPDATE_STRIPE_PRICESLIST_SUCCESS = "[AK_StripeGetPricesList API] Calling Stripe PricesList SUCCESS";
export const GET_STRIPE_PRICESLIST_ON_APP_LOAD = "[On App Load] Calling AK_StripeGetPricesList";

export const UPDATE_STRIPE_CUSTOMER_INFO_ERROR = "[From Effect - AK_StripeUpdateCustomer API] Update Stripe customerInfo ERROR";
export const UPDATE_STRIPE_CUSTOMER_INFO_SUCCESS = "[From Effect - AK_StripeUpdateCustomer API] Update Stripe customerInfo SUCCESS";
export const UPDATE_STRIPE_CUSTOMER_INFO_FROM_BILLINGPAGE = "[From billing page] Update Stripe customerInfo";

export const UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_ERROR = "[From Effect - AK_StripeCustomerInfo API] Update Stripe customerInfo & subscription ERROR";
export const UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS = "[From Effect - AK_StripeCustomerInfo API] Update Stripe customerInfo & subscription SUCCESS";
export const UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_FROM_CREATE = "[Create Stripe Customer] Update Stripe customerInfo & subscription";
export const GET_STRIPE_CUSTOMER_INFO_ON_APP_LOAD = "[On App Load] Calling AK_StripeCustomerInfo";
export const GET_STRIPE_CUSTOMER_INFO_ON_PLANPAGESUBP= "[Plan Page after a subscription payment] Calling AK_StripeCustomerInfo";

export const ADD_PRODUCT_FEATURES = "[UserPlanStore] Add Product Features";
export const UPDATE_STRIPE_ID = "[UserPlanStore] Update Stripe ID";

export const UPDATE_CRM_BASED_CUSTOMERINFO_SUCCESS = "[From Effect - updateCRM API] Update CRM based on customer info update SUCCESS";
export const UPDATE_CRM_BASED_CUSTOMERINFO_ERROR = "[From Effect - updateCRM API] Update CRM based on customer info update ERROR";

export class UpdateCrmBasedCustomerinfoSuccess implements Action {
    readonly type = UPDATE_CRM_BASED_CUSTOMERINFO_SUCCESS;
    constructor() { }
}

export class UpdateCrmBasedCustomerinfoError implements Action {
    readonly type = UPDATE_CRM_BASED_CUSTOMERINFO_ERROR;
    constructor() { }
}

export class UpdateStripeCustomerError implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_ERROR;
    constructor() { }
}

export class UpdateStripeCustomerInvoicesError implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INVOICES_ERROR;
    constructor() { }
}
export class UpdateStripeCustomerInvoicesSuccess implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INVOICES_SUCCESS;
    constructor(public payload: any) { }
}
export class GetStripeCustomerInvoicesBillingpage implements Action {
    readonly type = GET_STRIPE_CUSTOMER_INVOICES_BILLINGPAGE;
    constructor() { }
}

export class UpdateRecurringInterval implements Action {
    readonly type = UPDATE_RECURRING_INTERVAL;
    constructor(public payload: 'year' | 'month') { }
}
export class UpdatePlanType implements Action {
    readonly type = UPDATE_PLAN_TYPE;
    constructor(public payload: any) { }
}

export class UpdateStripeCustomerTaxidError implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_TAXID_ERROR;
    constructor() { }
}
export class UpdateStripeCustomerTaxidSuccess implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_TAXID_SUCCESS;
    constructor(public payload: any) { }
}
export class GetStripeCustomerTaxidOnAppLoad implements Action {
    readonly type = GET_STRIPE_CUSTOMER_TAXID_ON_APP_LOAD;
    constructor(public payload: string) { }
}


export class UpdateStripeTaxidTypesError implements Action {
    readonly type = UPDATE_STRIPE_TAXID_TYPES_ERROR;
    constructor() { }
}
export class UpdateStripeTaxidTypesSuccess implements Action {
    readonly type = UPDATE_STRIPE_TAXID_TYPES_SUCCESS;
    constructor(public payload: any) { }
}
export class GetStripeTaxidTypesOnAppLoad implements Action {
    readonly type = GET_STRIPE_TAXID_TYPES_ON_APP_LOAD;
    constructor() { }
}

export class CreateFreePlanSubscriptionPlansPage implements Action {
    readonly type = CREATE_FREE_PLAN_SUBSCRIPTION_PLANS_PAGE;
    constructor(public payload: {customerId: string, items: any}) { }
}
export class CreateFreePlanSubscriptionOnAppLoad implements Action {
    readonly type = CREATE_FREE_PLAN_SUBSCRIPTION_ON_APP_LOAD;
    constructor(public payload: {customerId: string, items: any}) { }
}

export class UpdateSubscriptionErrorStripeService implements Action {
    readonly type = UPDATE_SUBSCRIPTION_ERROR_STRIPE_SERVICE;
    constructor() { }
}
export class UpdateSubscriptionSuccessPlanPage implements Action {
    readonly type = UPDATE_SUBSCRIPTION_SUCCESS_PLAN_PAGE;
    constructor(public payload: any) { }
}

export class DeleteSubscriptionFromPlansPageError implements Action {
    readonly type = DELETE_SUBSCRIPTION_ERROR;
    constructor() { }
}
export class DeleteSubscriptionFromPlansPageSuccess implements Action {
    readonly type = DELETE_SUBSCRIPTION_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteSubscriptionFromBillingPage implements Action {
    readonly type = DELETE_SUBSCRIPTION_FROM_BILLING_PAGE;
    constructor(public payload: {subscriptionId: string}) { }
}

export class DeleteSubscriptionFromPlansPage implements Action {
    readonly type = DELETE_SUBSCRIPTION_FROM_PLANS_PAGE;
    constructor(public payload: {subscriptionId: string}) { }
}

export class DeleteSubscriptionFromEnablingTrialEffectError implements Action {
    readonly type = DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL_ERROR;
    constructor() { }
}
export class DeleteSubscriptionFromEnablingTrialEffectSuccess implements Action {
    readonly type = DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteSubscriptionFromEnablingTrialEffect implements Action {
    readonly type = DELETE_SUBSCRIPTION_FROM_ENABLIG_TRIAL;
    constructor(public payload: {subscriptionId: string}) { }
}

export class CreateSubscriptionEnablingTrialFromPlansPage implements Action {
    readonly type = CREATE_SUBSCRIPTION_ENABLING_TRIAL_FROM_PLANS_PAGE;
    constructor(public payload: {enableAutomaticTax: boolean, customerId: string, items: any, enableTrial: boolean, discounts: any, replacedSubscriptionId: string}) { }
}

export class CreateSubscriptionError implements Action {
    readonly type = CREATE_SUBSCRIPTION_ERROR;
    constructor() { }
}
export class CreateSubscriptionSuccess implements Action {
    readonly type = CREATE_SUBSCRIPTION_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateSubscriptionFromEffect implements Action {
    readonly type = CREATE_SUBSCRIPTION_FROM_EFFECT;
    constructor(public payload: {enableAutomaticTax: boolean, customerId: string, items: any, enableTrial: boolean, discounts: any}) { }
}
export class CreateSubscriptionFromPlansPage implements Action {
    readonly type = CREATE_SUBSCRIPTION_FROM_PLANS_PAGE;
    constructor(public payload: {enableAutomaticTax: boolean, customerId: string, items: any, enableTrial: boolean, discounts: any}) { }
}


export class UpdateStripePricesListError implements Action {
    readonly type = UPDATE_STRIPE_PRICESLIST_ERROR;
    constructor() { }
}
export class UpdateStripePricesListSuccess implements Action {
    readonly type = UPDATE_STRIPE_PRICESLIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetStripePricesListOnAppLoad implements Action {
    readonly type = GET_STRIPE_PRICESLIST_ON_APP_LOAD;
    constructor() { }
}

export class UpdateStripeCustomerInfoError implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_ERROR;
    constructor() { }
}
export class UpdateStripeCustomerInfoSuccess implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_SUCCESS;
    constructor(public payload: any) { }
}
export class UpdateStripeCustomerInfoFromBillingPage implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_FROM_BILLINGPAGE;
    constructor(public payload: {customerId: string, detailsToUpdate: any}) { }
}

export class UpdateStripeCustomerInfoSubscriptionError implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_ERROR;
    constructor() { }
}
export class UpdateStripeCustomerInfoSubscriptionSuccess implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_SUCCESS;
    constructor(public payload: any) { }
}
export class UpdateStripeCustomerInfoSubscriptionFromCreate implements Action {
    readonly type = UPDATE_STRIPE_CUSTOMER_INFO_SUBSCRIPTION_FROM_CREATE;
    constructor(public payload: any) { }
}
export class GetStripeCustomerInfoOnPlanPageSubP implements Action {
    readonly type = GET_STRIPE_CUSTOMER_INFO_ON_PLANPAGESUBP;
    constructor() { }
}
export class GetStripeCustomerInfoOnAppLoad implements Action {
    readonly type = GET_STRIPE_CUSTOMER_INFO_ON_APP_LOAD;
    constructor() { }
}
export class AddProductFeatures implements Action {
    readonly type = ADD_PRODUCT_FEATURES;
    constructor(public payload: {productFeatureIdsList: string[], productFeaturesList: any[]}) { }
}

export class UpdateStripeId implements Action {
    readonly type = UPDATE_STRIPE_ID;
    constructor(public payload: string) { }
}

export type UserPlanActionsList = 
            AddProductFeatures | 
            UpdateStripeId | 
            UpdateStripeCustomerInfoSubscriptionSuccess |
            UpdateStripePricesListSuccess |
            UpdateStripeCustomerInfoSubscriptionFromCreate |
            CreateSubscriptionSuccess |
            UpdateStripeTaxidTypesSuccess |
            UpdateStripeCustomerTaxidSuccess |
            UpdatePlanType |
            UpdateRecurringInterval |
            UpdateSubscriptionSuccessPlanPage |
            DeleteSubscriptionFromPlansPageSuccess |
            UpdateStripeCustomerInvoicesSuccess |
            UpdateStripeCustomerInfoSuccess;