import { Action } from "@ngrx/store";

export const UPDATE_FINISHAUDITREPORT_FROM_AI = "[From App Component] Update finishAuditReportFromAi.";
export class UpdateFinishAuditReportFromAi implements Action {
    readonly type = UPDATE_FINISHAUDITREPORT_FROM_AI;
    constructor() { }
}

export const UPDATE_MONACO_LOADING_MSG = "[From App Component] Update monacoLoadingMsg.";
export class UpdateMonacoLoadingMsg implements Action {
    readonly type = UPDATE_MONACO_LOADING_MSG;
    constructor(public payload: string) { }
}

export const UPDATE_SMARTCONTRACT_CODE = "[From App Component] Update smart contract code.";
export class UpdateSmartcontractCode implements Action {
    readonly type = UPDATE_SMARTCONTRACT_CODE;
    constructor(public payload: string) { }
}

export const UPDATE_USER_WEBSOCKET_CONNECTION_ID = "[From App Component] Update user websocket connection id.";
export class UpdateUserWebsocketConnectionId implements Action {
    readonly type = UPDATE_USER_WEBSOCKET_CONNECTION_ID;
    constructor(public payload: string) { }
}

export const CALL_AI_KODE_SHERPA_CHAT = "[aiKodeSherpaChat API] Call aiKodeSherpaChat API.";
export class CallAIKodeSherpaChat implements Action {
    readonly type = CALL_AI_KODE_SHERPA_CHAT;
    constructor(public payload: any) { }
}
export const CALL_AI_KODE_SHERPA_CHAT_SUCCESS = "[aiKodeSherpaChat API SUCCESS] Call aiKodeSherpaChat API.";
export class CallAIKodeSherpaChatSuccess implements Action {
    readonly type = CALL_AI_KODE_SHERPA_CHAT_SUCCESS;
    constructor(public payload: any) { }
}
export const CALL_AI_KODE_SHERPA_CHAT_ERROR = "[aiKodeSherpaChat API ERROR] Call aiKodeSherpaChat API.";
export class CallAIKodeSherpaChatError implements Action {
    readonly type = CALL_AI_KODE_SHERPA_CHAT_ERROR;
    constructor() { }
}

export type ServicesActionsList = 
        UpdateUserWebsocketConnectionId |
        CallAIKodeSherpaChatSuccess |
        UpdateSmartcontractCode |
        UpdateMonacoLoadingMsg |
        UpdateFinishAuditReportFromAi;